import { FC, lazy, LazyExoticComponent } from 'react'
import { MenuOption } from './models/MenuOption'

const EmptyPage = lazy(() => import('./pages/emptyPage'))

export interface IRoute {
    id: number
    url: string
    components: LazyExoticComponent<FC<any>>
    children?: Array<IRoute>
}

export const useSiteRoutes = (options: MenuOption[]): IRoute[] => {
    const result:IRoute[] = [];
    // result.push({
    //     id: 0,
    //     url: '/*',
    //     components: EmptyPage,
    //     children: [],
    // })
    return result;
}
