import { useLayoutEffect, useState } from 'react'

const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState<any>({
        width: 0,
        height: 0,
    })

    const [isMobile, setIsMobile] = useState<boolean>(false)

    const handleSize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        })

        setIsMobile(window.innerWidth < 1024 ? true : false)
    }

    useLayoutEffect(() => {
        handleSize()

        window.addEventListener('resize', handleSize)

        return () => window.removeEventListener('resize', handleSize)
    }, [])

    return { windowSize, isMobile }
}

export default useWindowSize
