export enum SideBarReducerAction {
    COLLAPSE = 0,
    EXPANDED = 1,
}

export interface ISideBarState {
    stateSideBar: boolean
}

export const sideBarReducer = (
    state: ISideBarState,
    action: {
        type: SideBarReducerAction
    }
): ISideBarState => {
    switch (action.type) {
        case SideBarReducerAction.COLLAPSE: {
            const newState = { ...state, stateSideBar: false }

            return newState
        }
        case SideBarReducerAction.EXPANDED: {
            const newState = { ...state, stateSideBar: true }

            return newState
        }
        default:
            throw 'Unrecognized action'
    }
}
