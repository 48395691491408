export enum AudioReducerAction {
    TOGGLE_MUTE = 0,
}

export interface IAudioState {
    muted: boolean
}

export const audioReducer = (
    state: IAudioState,
    action: { type: AudioReducerAction, callback?: (s:IAudioState) => void }
): IAudioState => {
    switch (action.type) {
        case AudioReducerAction.TOGGLE_MUTE:{
            const newState  = { ...state, muted: !state.muted };
            action.callback?.(newState);
            return newState;
        }
        default:
            throw 'Unrecognized action'
    }
}