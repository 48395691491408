import { MenuOption } from '../../../models/MenuOption'
import { MenuType } from '../../../models/MenuType'

export enum MenuReducerAction {
    SELECT_OPTION = 0,
    RESET_OPTIONS = 1,
}

export interface IMenuState {
    options: MenuOption[]
    selectedOptions?: { [key: string]: MenuOption | undefined }
}

export const menuReducer = (
    state: IMenuState,
    action: { type: MenuReducerAction; value: any, callback?: (n:IMenuState, o:IMenuState) => void }
): IMenuState => {
    switch (action.type) {
        case MenuReducerAction.SELECT_OPTION: {
            const { type, index } = action.value as { type: MenuType, index: number };
            const selectedOption: MenuOption | undefined =
                type === MenuType.TopBar ? state.options[index] :
                    type === MenuType.LeftBar ? state.selectedOptions?.[MenuType.TopBar.toString()]?.children?.[index] :
                        undefined;

            const selectedOptions = {
                ...state.selectedOptions,
                [type.toString()]: selectedOption
            };

            if (type === MenuType.TopBar)
                delete selectedOptions[MenuType.LeftBar];

            const newState = {
                ...state,
                selectedOptions: selectedOptions
            };
            setUrl(newState);
            action.callback?.(newState, state);

            return newState;
        }
        case MenuReducerAction.RESET_OPTIONS:
            const newState = {...state, selectedOptions: {}};
            setUrl(newState);
            action.callback?.(newState, state);
            return newState;
        default:
            throw 'Unrecognized action'
    }
};

function setUrl(state: IMenuState) {
    const selectedTopbarOption = state.selectedOptions?.[MenuType.TopBar.toString()];
    const selectedSidebarOption = state.selectedOptions?.[MenuType.LeftBar.toString()];
    const path = selectedTopbarOption ? '/' + selectedTopbarOption.id +
        (selectedSidebarOption ? '/' + selectedSidebarOption.id : '') : '';
    window.history.replaceState(null, selectedTopbarOption?.nameLabel ?? "Sylex Core", path || "/")
}
