import { Routes, Route, BrowserRouter } from 'react-router-dom'
import { IRoute, useSiteRoutes } from './routes'
import SpinnerProvider from './contexts/SpinnerContext/SpinnerContext'
import SiteStateProvider from './contexts/SiteStateContext/SiteStateContext'
import { QueryClient, QueryClientProvider } from 'react-query'
import i18n from './i18n'
import './App.scss'
import defaultOptions from './assets/data/MenuOptions.json'
import Layout from './components/Layout/Layout.lazy'
import useAnalyticsEventTracker from '../src/hooks/useAnalyticsEventTracker'

import ReactGA4 from 'react-ga4'
import { useEffect } from 'react'
import useWindowSize from './hooks/useWindowSize'
import LayoutMobile from './components/LayoutMobile/LayoutMobile.lazy'

ReactGA4.initialize([
    {
        trackingId: process.env.REACT_APP_TRACKING_GOOGLE_ANALYTIC
            ? process.env.REACT_APP_TRACKING_GOOGLE_ANALYTIC
            : '',
    },
])

const queryClient = new QueryClient()

function App() {
    const { eventTrackerInitPage } = useAnalyticsEventTracker()
    const queryParams = new URLSearchParams(window.location.search)
    const lng = queryParams.get('lng')
    i18n.changeLanguage(lng ?? 'en_US')
    const routes = useSiteRoutes(defaultOptions)
    const [_, topBarOption, leftBarOption] = window.location.pathname.split('/')
    const { isMobile } = useWindowSize()
    useEffect(() => {
        ReactGA4._gaCommandSendPageview(window.location.pathname, {})
        eventTrackerInitPage()
    }, [])

    return (
        <div className="App">
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <SiteStateProvider
                        initialOptions={defaultOptions}
                        topBarOptionId={topBarOption}
                        leftBarOptionId={leftBarOption}
                    >
                        <SpinnerProvider>
                            <Routes>
                                {routes.map((route: IRoute) => (
                                    <Route
                                        key={route.id}
                                        path={route.url}
                                        element={<route.components />}
                                    >
                                        {route.children?.map(
                                            (subroute: IRoute) => (
                                                <Route
                                                    key={subroute.id}
                                                    path={subroute.url}
                                                    element={
                                                        <subroute.components />
                                                    }
                                                />
                                            )
                                        )}
                                    </Route>
                                ))}
                                <Route
                                    path="/:topBarOption?/:leftBarOption?"
                                    element={
                                        !isMobile ? (
                                            <Layout />
                                        ) : (
                                            <LayoutMobile />
                                        )
                                    }
                                />
                            </Routes>
                        </SpinnerProvider>
                    </SiteStateProvider>
                </BrowserRouter>
            </QueryClientProvider>
        </div>
    )
}

export default App
