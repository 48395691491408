import React, { lazy, Suspense } from 'react'

const LazyLayout = lazy(() => import('./LayoutMobile'))

const LayoutMobile = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode }
) => (
    <Suspense fallback={null}>
        <LazyLayout {...props} />
    </Suspense>
)

export default LayoutMobile
