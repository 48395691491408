import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import LoadingPane from '../../components/LoadingPane/LoadingPane.lazy'
import ISpinnerManager from './ISpinnerManager'

/**
 * Spinner provider properties
 */
export interface SpinnerProviderProps {
    children?: JSX.Element | JSX.Element[] | string
}

/**
 * Spinner context
 */
const SpinnerContext = React.createContext<ISpinnerManager>(undefined!)

/**
 * Spinner hook
 * @returns Spinner context
 */
export function useSpinner(): ISpinnerManager {
    return useContext(SpinnerContext)
}

/**
 * Spinner provider
 * @returns Component
 */
export default function SpinnerProvider({ children }: SpinnerProviderProps) {
    const [showSpinner, setShowSpinner] = useState(0)
    const [hideContent, setHideContent] = useState(false)
    const { t } = useTranslation()
    const [msg, setMessage] = useState<string | null>(t('Loading...'))

    // Context value
    const value: ISpinnerManager = {
        isSpinnerDisplayed: showSpinner > 0,
        show: (hideContent?: boolean, message?: string) => {
            setShowSpinner((prev) => prev + 1)
            setHideContent(!!hideContent)
            setMessage(message || message === '' ? message : t('Loading...'))
        },
        hide: (force?: boolean) => {
            setShowSpinner((prev) => (force ? 0 : prev--))
            setHideContent(false)
        },
        runAsync: async (
            action: () => Promise<void>,
            hideContent?: boolean,
            message?: string,
            force?: boolean
        ) => {
            try {
                setShowSpinner((prev) => prev + 1)
                setHideContent(!!hideContent)
                setMessage(
                    message || message === '' ? message : t('Loading...')
                )

                await action()

                setShowSpinner((prev) => (force ? 0 : prev--))
                setHideContent(false)

                return true
            } catch (error) {
                throw error
            }
        },
    }

    return (
        <SpinnerContext.Provider value={value}>
            {!!showSpinner && (
                <LoadingPane message={msg} fullOverlay={hideContent} />
            )}
            <div
                data-testid="ContentContainer"
                style={{ display: hideContent ? 'none' : 'block' }}
            >
                {children}
            </div>
        </SpinnerContext.Provider>
    )
}
