import React from 'react'
import ReactGA from 'react-ga4'

export const useAnalyticsEventTracker = (category = 'Blog category') => {
    const eventTracker = (action = 'test action', label = 'test label') => {
        ReactGA.event({ category, action, label })
    }

    const eventTrackerInitPage = () => {
        ReactGA.event({
            category: 'Usuarios',
            action: 'Visitó a la página',
            label: 'Inicio',
        })
    }
    return { eventTracker, eventTrackerInitPage }
}

export default useAnalyticsEventTracker
